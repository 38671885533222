.btn-light{
    color: rgba(0, 0, 0, 0.8);
}
.btn{
    font-variant: all-small-caps;
    border-radius: 10px;

    padding-left: 12px;
padding-right: 12px;
transition: .3s;
font-weight: 300;
padding-bottom: 4px;
padding-top: 3px;
}
hr{
    opacity: 0.05;
}

.alert{
backdrop-filter:blur(10px);
}

.alert-danger{
    background: rgba(249, 199, 199, 0.43);
    border-color: rgba(181, 108, 108, 0.5);
}
.form-control{
    border-radius: 10px;
    font-size: 14px;
    height: 32px;
}

small{
    font-size: 12px;
color: rgba(0,0,0,0.5);
font-weight: 300;
}

.btn-primary {
    color: #f9f2ea;
    background-color:#9e896e;
    border-color: #988160;
}
.btn-primary:hover {
    color: #f9f2ea;
    background-color: #c29b69;
    border-color: #ab7f46;
}
.btn-primary:active {
    color: white;
    background-color: #c2965c;
    border-color: #ab7f46;
}

.btn-danger {
    color: #772828;
background-color: #dbb8bc;
border-color: #e0c5c8;
}

.btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    }
    

  
  /*Убрать полосу прокрутки для всей страницы*/
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }